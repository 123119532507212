.subtotal{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 400px;
   height: auto;
   padding: 20px;
   background-color: #f3f3f3;
   border: 1px solid #ddd;
   border-radius: 3px;
}
.subtotalGift{
   display: flex;
   align-items: center;
}
.subtotalGift > input{
   margin: 5px
}

.subtotal > button{
   background-color: #f0c14b;
   border-radius: 2px;
   width: 100%;
   height: 30px;
   border: 1px solid;
   margin: 10px;
   border-color: #a88734 #9c7e31 #846a29;
   color: #111;
   font-size: 20px;
}
.subtotal > button:hover{
   box-shadow: 1px 1px 4px #846a29;
}