.login{
   background-color: white;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
}
.loginContainer{
   width: 300px;
   height: fit-content;
   display: flex;
   flex-direction: column;
   padding: 20px;
   border: 1px solid lightgrey;
   border-radius: 15px;
   background-color: #f8f8f8;
}

.loginContainer > h1{
   font-weight: 500;
   margin-bottom: 20px;
}

.loginContainer > form > h5{
   margin-bottom: 5px;
}

.loginContainer > form > input{
   height: 30px;
   margin-bottom: 10px;
   background-color: white;
   width: 98%;
   border: 1px solid;
}

.loginContainer >p{
   margin-top: 15px;
   font-size: 12px;
}
.loginSigninBtn{
   background-color: #f0c14b;
   border-radius: 2px;
   width: 100%;
   height: 30px;
   border: 1px solid;
   margin-top: 10px;
   border-color: #a88734 #9c7e31 #846a29;
}
.createAccBtn{
   border: 1px solid darkgrey;
   border-radius: 2px;
   width: 100%;
   height: 30px;
   margin-top: 10px;
}
.createAccBtn:hover{
   background-color: #f0c14b;
   border-radius: 15px;
}
.loginLogo{
   margin: 20px 20px;
   height: 64px;
   object-fit: contain;
}