*{
   text-decoration: none;
}
.header{
   display: flex;
   width: 100vw;
   height: 52px;
   background-color: #021024;
   align-items: center;
   justify-content: flex-end;
}
.logo{
   display: flex;
   float: left;
   flex: .55;
   align-items: center;
   margin: 25px;
}
.logo>a{
   color: #fff;
   img{
      height: 40px;
   }
}
.nav{
   display: flex;
   float: right;
   flex: .45;
   width: 45vw;
   justify-content: space-evenly;
}
.nav>a{
   display: flex;
   align-items: center;
   color: #fefefe;
   font-weight: 500;
}
.nav>a:hover{
   color: #3950ff;
}
.profile{
   display: flex;
   align-items: center;
}
.ac{
   padding: 5px;
   font-weight: 500;
   a{
      color:#283592;
   }
}
.l1{
   font-size: 16px;
}
.l2{
   font-size: 12px;
}
.ac>a{
   color: #fff;
}
.ac>a:hover{
   color: #0077ff;
   text-decoration: underline;
}



.shownav{
   visibility: visible;
}


@media screen and (max-width:800px) {
   .nav{
      width: 100%;
      flex: auto;
   }
   .ac{
      display: none;
   }
}
