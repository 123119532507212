.product{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-end;
   margin: 10px;
   padding: 24px;
   width: auto;
   max-width: 600px;
   min-width: 300px;
   background-color: #fff;
   border-radius: 10px;
   z-index: 1;
}
.product:hover{
   box-shadow: 1px 4px 8px #8e8e8e;
   outline: 1px solid #000;
}
.productImg{
   display: flex;
   height: 300px;
   width: 100%;
   object-fit: contain;
   align-items: center;
   justify-content: center;
}
#pImg{
   height: 300px;
   width: auto;
}
.productInfo{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
}
.productTitle{
   display: flex;
   align-items: center;
   text-align: left;
}


.product > img{
   max-height: 200px;
   width: 100%;
   object-fit: contain;
   margin-bottom: 15px;
}

.qtyBtn{
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 5px;
   border: 1px solid #000;
   background-color: #eaeaea;
}
.qty{
   padding: 5px;
   border-left: 1px solid #898989;
   border-right: 1px solid #898989;
}
.btn:hover{
   border: none;
   background-color: #cdcdcd;
}


.productPrice{
   display: flex;
   float: left;
   width: 100%;
   height: 42px;
   gap: 20px;
   align-items: baseline;
}
.price{
   color: rgb(217, 0, 0);
   font-size: 32px;
   gap: 5px;
   display: flex;
   align-items: center;
}
.plable{
   background-color: rgb(217, 0, 0);
   color: #fff;
   padding: 1px 2px 1px 2px;
}
.small{
   font-size: 18px;
}
.origPrice{
   height: 34px;
   padding: 5px;
   color: #898989;
   text-decoration: line-through;
   background-color: #ebebeb;
}

.productRating{
   display: flex;
   width: 100%;
   margin-top: 10px;
}
.productRating>span{
   width: 100%;
   display: flex;
   float: left;
   align-items: center;
}
.addCartBtn{
   display: flex;
   height: 32px;
   width: 200px;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   color: #000;
   border-radius: 25px;
   background-color: #ffde0c;
}
.addCartBtn:hover{
   color:#000;
   text-decoration: none;
   border: 1px solid #000;
   box-shadow: 1px 2px 5px #898989;
}


@media screen and (max-width:600px) {
   .product{
      margin: 10px 5px;
   }
}