::-webkit-scrollbar{
  display: none;
}
.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
}

.page404{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.page404>.errHead{
  font-size: 40px;
  font-weight: 500;
  h3{
    font-weight: 100;
  }
}
.errStack{
  display: flex;
  position: relative;
  text-align: left;
  border-radius: 5px;
  border: 1px solid red;
}
.alert{
  border-radius: 15px;
  /* outline: 1px solid red; */
}
.errPara{

}
.errFoot{
  display: flex;
  width: 100%;
  align-items: center;
  float: right;
}
.errFoot>.btns{
  display: flex;
}
