.store{
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin: auto;
   max-width: 1500px;
   background-color: #ebebeb;
}

.storeBanner{
   height: auto;
   width: 100vw;
}
.storeImage{
   height: 100%;
   width: 100vw;
   z-index: 1;
   margin-bottom: -250px;
   mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
}

.storeRow{
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-auto-columns: auto;
   z-index: 1;
   margin: auto;
}



@media screen and (max-width: 1080px) {
   .storeRow{
      grid-template-columns: repeat(2, 1fr);
   }
   
}
@media screen and (max-width: 800px) {
   .storeRow{
      grid-template-columns: repeat(1, 1fr);
   }
}