.intro{
   display: flex;
   flex-direction: column;
   background-color: #fbfbfb;
   padding: 64px;
   margin: auto;
   z-index: 1;

}
.tabContext{
   z-index: 1;
   width: 100%;
}
.tabContenet{
   z-index: 1;
   width: 100%;
   text-align: left;
}

@media screen and (max-width:800px) {
   .intro{
      padding: 24px;
   }
}
