.checkout{
   display: flex;
   padding: 20px;
   background-color: white;
   height: max-content;
}

.checkoutAds{
   width: 100%;
   margin-bottom: 10px;
}

.checkoutTitle{
   margin-right: 10px;
   padding: 10px;
   border-bottom: 1px solid lightgrey;
}

