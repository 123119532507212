.home{
   width: 100vw;
   background-image: linear-gradient(100deg, #a0bef8, #b5f0f0);
}
.homeBanner{
   width: 100vw;
   mask-image: linear-gradient(180deg, rgb(210, 210, 210),rgba(0,0,0,1), rgba(0,0,0,0));
   margin: auto;
   margin-bottom: -10px;
   height: max-content;
}
.bannerText{
   width: 100vw;
   display: flex;
   flex-direction: column;
   position: absolute;
   top: 20vh;
   align-items: center;
   justify-content: center;
   color: #000000;
   z-index: 1;
}
.companyHeading{
   margin: auto;
   align-items: center;
   font-size: 5rem;
   backdrop-filter: blur(2px);
}
.bannerText>.tagline{
   font-size: 26px;
   display: flex;
   float: left;
   width: 50vw;
   color: #383838;
}

.quotationBox{
   display: flex;
   float: left;
   left: 25px;
   top: 100vh;
   flex-direction: column;
   position: absolute;
   width: 450px;
   contain: content;
   text-align: left;
   padding: 24px;
   background-color: #e5e5e552;
   outline: 1px solid #fff;
   backdrop-filter: blur(8px);
   border-radius: 10px;
}
.quotationBox:hover{
   outline: 1px solid #b5f0f061;
   color: #000;
}
.qicon{
   height: auto;
   width: 72px;
}.up{
   rotate: 180deg;
}
.quote{
   font-size: 32px;
   font-family: "Poppins", sans-serif;
   font-weight: 200;
   margin-left: 25px;
   font-style: normal;
   h1{
      display: inline;
   }
}
.subscript{
   display: flex;
   margin-top: -42px;
   flex-direction: column;
   align-items: flex-end;
   justify-content: right;
   color: #000;
   font-family: "Poppins", sans-serif;
}
.homeBody{
   display: flex;
   align-items: center;
   padding: 40px;
   background-image: url(./assets/bg-business.png);
   background-size: cover;
   background-repeat: no-repeat;
}


.leadContainer{
   width: 350px;
   display: flex;
   flex: .25;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 32px;
   /* border: 1px solid #000; */
   background-color: #fefefe;
   backdrop-filter: blur(8px);
   border-radius: 1px;
}
.leadImg{
   display: flex;
   height: 300px;
   width: 300px;
   border-radius: 50%;
   background-color: #a1c8ff;
   mask-size: contain;
   mask-repeat: no-repeat;
}
.leadPara{
   display: flex;
   float: right;
   width: 100%;
   font-size: 18px;
   font-weight: 100;
   font-family: "Ubuntu", sans-serif;
}









@media screen and (max-width: 800px) {
   .companyHeading{
      margin-top: -70px;
      align-items: center;
      font-size: 32px;
   }
   .home{
      flex-direction: column;
   }
   .bannerText>.tagline{
      backdrop-filter: blur(8px);
      width: 80vw;
      color: #fefefe;
      align-items: center;
      font-size: 16px;
   }

   .quotationBox{
      width: 300px;
      height: auto;
      float: none;
      position: inherit;
      color: #000;
      margin: auto;
   }
   
   .quote{
      font-size: x-large;
      font-weight: 200;
   }
   .homeBody{
      width: 100vw;
      flex-direction: column;
      row-gap: 20px;
      height: max-content;
   }
   .leadContainer{
      flex: 1;
      align-items: center;
      justify-content: center;
   }
}